import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { validateFormInputSellingPrice } from "../../helpers/functions/validateTextInput";
import api from "../../helpers/api";
import { tableHeadStockIn, tableHeadAddStockIn } from "../../utils/Constants";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { BsFillTrash3Fill } from "react-icons/bs";
import ModalDetail from "../../components/ModalDetail";
import { Box, Container, Paper, Stack } from "@mui/material";

function StockIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isModalDetail, setIsModalDetail] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  // ==================
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ==================
  const [stockInList, setStockInList] = useState([]);
  const [stockInSelected, setStockInSelected] = useState("");
  const [stockInDetail, setStockInDetail] = useState([]);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================
  const [sellingPriceInput, setSellingPriceInput] = useState("");
  const [statusSelected, setStatusSelected] = useState([]);
  // ==================
  const [isRemoveProduct, setIsRemoveProduct] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [dateStockIn, setDateStockIn] = useState(null);
  const [note, setNote] = useState("");
  const [productOrders, setProductOrders] = useState([]);
  // ==================
  const [productList, setProductList] = useState([]);
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  // ==================
  const [variantList, setVariantList] = useState([]);
  const [variantNameSelected, setVariantNameSelected] = useState([]);
  const [searchVariantName, setSearchVariantName] = useState("");
  const [additional, setAdditional] = useState({
    serviceCharge: "0",
    taxCharge: "0",
    taxResto: "0",
    cashPayment: 0,
    edcPayment: 0,
    rounding: 0,
    netPayment: 0,
    discount: 0,
    additionalCost: 0,
    paymentType: "Cash",
    paymentTypeCode: 456,
    transactionType: "STOCK IN",
    transactionTypeCode: 312,
  });
  // ==================

  const onGetStockInList = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api
        .getStockInData(
          branchCodeSelected,
          searchProductName,
          rowsPerPage,
          currentPage
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            // setTotalRecord(res.data.totalData);
            setStockInList(res.data.data);
            setTotalRecord(res.data.totalPage * res.data.limit);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchList(updatedBranchList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onSetSellingPrice = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .editSellingPrice(
          stockInSelected.id,
          statusSelected.value,
          sellingPriceInput,
          stockInSelected.minQuantity
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onGetProducts = async () => {
    try {
      const res = await api
        .getProduct(
          searchProductName,
          ""
          // branchNameSelected.length !== 0 ? branchNameSelected.branchCode : ""
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setProductList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetVariantProduct = async (masterProductCode) => {
    try {
      const res = await api
        .getDetailProduct(masterProductCode)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setVariantList(res.data.attachments);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetDetailStockIn = async (data) => {
    setIsSpinner(true);
    try {
      const res = await api
        .getDetailStock(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            setStockInDetail(res.data);
            setStockInSelected(data);
            setIsModalDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const saveAddStockIn = async () => {
    const sumTotal = productOrders.reduce(
      (accumulator, current) => accumulator + parseInt(current.netPrice),
      0
    );
    setIsOpenModal(false);
    setIsSpinner(true);
    const bodyStockIn = {
      item: productOrders.length,
      note: note,
      TaCharge: additional.taxCharge,
      discount: additional.discount,
      rounding: additional.rounding,
      taxResto: additional.taxResto,
      edcPayment: additional.edcPayment,
      netPayment: sumTotal,
      cashPayment: sumTotal,
      paymentType: additional.paymentType,
      serviceCharge: additional.serviceCharge,
      additionalCost: additional.additionalCost,
      paymentTypeCode: additional.paymentTypeCode,
      transactionType: additional.transactionType,
      transactionToCode: branchNameSelected.branchCode,
      transactionToName: branchNameSelected.branchName,
      transactionFromCode: branchNameSelected.branchCode,
      transactionFromName: branchNameSelected.branchName,
      transactionTypeCode: additional.transactionTypeCode,
      attachments: productOrders,
    };
    console.log("bodyStockIn", bodyStockIn);
    try {
      const res = await api
        .saveStockIn(
          dateStockIn,
          productOrders.length,
          note,
          additional.taxCharge,
          additional.discount,
          additional.rounding,
          additional.taxResto,
          additional.edcPayment,
          sumTotal,
          sumTotal,
          additional.paymentType,
          additional.serviceCharge,
          additional.additionalCost,
          additional.paymentTypeCode,
          additional.transactionType,
          branchNameSelected.branchCode,
          branchNameSelected.branchName,
          additional.transactionTypeCode,
          productOrders
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  // ===================
  useEffect(() => {
    const fetch = async () => {
      await onGetStockInList();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetStockInList();
    }
  }, [isReload]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    onGetProducts();
  }, [searchProductName]);

  const onSuccessSubmit = async () => {
    setBranchNameSelected([]);
    setSearchBranchName("");
    setNote("");
    setDateStockIn(null);
    setProductOrders([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const onCloseModal = () => {
    setErrors({});
    setIsOpenModal(false);
  };

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDetailStockIn = (itemSelected, indexSelected) => {
    onGetDetailStockIn(itemSelected);
  };

  const handleAddProduct = () => {
    setSearchProductName("");
    setProductOrders([
      ...productOrders,
      {
        note: "",
        brandId: 0,
        masterProductCode: "",
        variantName: "",
        imageUrl: "",
        netPrice: 0,
        quantity: 0,
        trxPrice: 0,
        brandName: "",
        productId: "",
        categoryId: 0,
        productSku: "",
        productCode: "",
        productName: "",
        categoryName: "",
        updatedQuantity: 0,
        defaultTrxPrice: "",
        basePrice: "",
      },
    ]);
  };

  const onHandleProductItemSelected = (currentIndex, itemSelector) => {
    const newProducts = [...productOrders];
    newProducts[currentIndex].note = "";
    newProducts[currentIndex].brandId = itemSelector.brandId;
    newProducts[currentIndex].imageUrl = itemSelector.imageUrl;
    newProducts[currentIndex].brandName = itemSelector.brandName;
    newProducts[currentIndex].productId = itemSelector.id;
    newProducts[currentIndex].categoryId = itemSelector.categoryId;
    newProducts[currentIndex].productName = itemSelector.productName;
    newProducts[currentIndex].categoryName = itemSelector.categoryName;
    newProducts[currentIndex].updatedQuantity = itemSelector.updatedQuantity;
    newProducts[currentIndex].masterProductCode =
      itemSelector.masterProductCode;

    newProducts[currentIndex].variantName = "";
    newProducts[currentIndex].productSku = "";
    newProducts[currentIndex].productCode = "";

    newProducts[currentIndex].netPrice = 0;
    newProducts[currentIndex].quantity = 0;
    newProducts[currentIndex].trxPrice = 0;

    setProductOrders(newProducts);
    setProductNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        productItemSelected: itemSelector,
        searchProductItem: itemSelector.variantName,
      };
      return updatedData;
    });
    setVariantNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        variantSelected: [],
        searchVariant: "",
      };
      return updatedData;
    });
  };

  const onHandleVariantItemSelected = (currentIndex, itemSelector) => {
    const duplicateProduct = productOrders.filter(
      (x) => x.productId === itemSelector.id
    );
    if (duplicateProduct.length == 0) {
      const newProducts = [...productOrders];
      newProducts[currentIndex].variantName = itemSelector.variantName;
      newProducts[currentIndex].productSku = itemSelector.productSku;
      newProducts[currentIndex].productCode = itemSelector.productCode;
      newProducts[currentIndex].defaultTrxPrice = itemSelector.defaultTrxPrice;
      newProducts[currentIndex].basePrice = itemSelector.defaultTrxPrice;

      setProductOrders(newProducts);
    }
  };

  const handleInputQuantityChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    newProducts[index].updatedQuantity = event.target.value;
    setProductOrders(newProducts);
  };

  const calculateTotalPrice = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].netPrice =
      newProducts[index].quantity * newProducts[index].defaultTrxPrice;
    setProductOrders(newProducts);
  };

  const onHandleRemoveProductOrder = (index) => {
    const newArray = [...productOrders];
    const newArrayProductSelected = [...productNameSelected];
    const newArrayVariantSelected = [...variantNameSelected];

    newArray.splice(index, 1);
    newArrayProductSelected.splice(index, 1);
    newArrayVariantSelected.splice(index, 1);

    setProductOrders(newArray);
    setProductNameSelected(newArrayProductSelected);
    setVariantNameSelected(newArrayVariantSelected);
    setSearchProductName("");

    if (productOrders.length === 0) {
      setProductNameSelected([]);
      setVariantNameSelected([]);
    }

    setTimeout(() => {
      setIsRemoveProduct(false);
    }, 300);
  };

  const validateForm = () => {
    const newErrors = {};
    if (
      !branchNameSelected ||
      branchNameSelected === null ||
      branchNameSelected.length === 0
    ) {
      newErrors.branchNameSelected = "Branch / Outlet is required to choose";
    }

    if (dateStockIn === null) {
      newErrors.dateStockIn = "Date must be fill";
    }
    return newErrors;
  };

  const validateFormProductOrder = () => {
    const hasEmptyFields = productOrders.some(
      (productItem) =>
        !productItem.masterProductCode ||
        !productItem.variantName ||
        !productItem.quantity ||
        !productItem.netPrice
    );

    if (productOrders.length === 0) {
      onError("Product Order must be added first...");
      return false;
    }

    if (hasEmptyFields) {
      onError(
        "Please ensure all product order have Product Name, Variant Name, Quantity, Price & Total filled."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const isValid = validateFormProductOrder();
    if (isValid) {
      saveAddStockIn();
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...stockInList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setStockInList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Stock In
    </Typography>,
  ];

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Stock In List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(!isOpenModal);
            }}
          >
            Add Stock In
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE OUTLET
              </Typography>
              <PickerSearch
                dataList={branchList}
                onInputChange={(event, newInputValue) => {
                  setSearchBranchName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setBranchCodeSelected(v.branchCode);
                  } else {
                    setBranchCodeSelected("");
                  }
                }}
                getOptionLabel={(branchList) => branchList.branchName || ""}
                labelPicker="Choose Outlet"
                placeholderPicker="search outlet"
                sizePicker="small"
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE FROM
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date From"
                dateValue={selectedDateFrom}
                onChangeDate={(val) => setSelectedDateFrom(val)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE TO
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date To"
                dateValue={selectedDateTo}
                onChangeDate={(val) => setSelectedDateTo(val)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                onGetStockInList();
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="stockin-list"
          tableName={"Stock In List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadStockIn}
          data={stockInList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handleDetailStockIn}
          handleRequestSort={handleRequestSort}
        />
      </Paper>

      {/* <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Stock In List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(!isOpenModal);
            }}
          >
            Add Stock In
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetStockInList();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableList
          tableStructure="stockin-list"
          tableName={"Stock In List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadStockIn}
          data={stockInList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handleDetailStockIn}
        />
      </div> */}

      <Modal
        fullScreen={true}
        modalTitle={"Add New Stock In"}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Stock In"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchNameSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameSelected(v);
                } else {
                  setBranchNameSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Branch / Outlet"}
              placeholderPicker={"search branch / outlet"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameSelected}
              helperText={errors.branchNameSelected}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormDatePicker
              labelFormDatePicker="Date Stock In"
              formSize={"large"}
              dateValue={dateStockIn}
              onChangeDate={(val) => setDateStockIn(val)}
              sx={{ marginTop: 2 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormInput
              label="Note"
              name="note"
              multiline
              rows={3}
              error={!!errors.note}
              helperText={errors.note}
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
                setErrors({});
              }}
              sx={{ marginTop: 2 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Button
              sx={{ marginTop: "15px", marginBottom: "15px" }}
              variant="outlined"
              onClick={() => handleAddProduct()}
              startIcon={<AddCircleIcon />}
            >
              Select Product
            </Button>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableContainer mt={1}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeadAddStockIn.map((item) => {
                      return (
                        <TableCell
                          // colSpan={item.size}
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#060270",
                            color: "white",
                            width: item.size,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                {isRemoveProduct == false ? (
                  <TableBody>
                    {productOrders.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={tableHeadAddStockIn.length}
                          align="center"
                          sx={{ py: 4 }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item>
                              <AddCircleIcon
                                color="secondary"
                                fontSize="large"
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                sx={{ color: "text.secondary" }}
                              >
                                {` No product found. You can add a new product using the
                        button above.`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {productOrders.map((item, index) => {
                          return (
                            <TableRow key={item.id}>
                              <TableCell width={"5%"}>{index + 1}</TableCell>
                              <TableCell width={"25%"}>
                                <PickerSearch
                                  value={
                                    productNameSelected[index]
                                      ?.productItemSelected
                                  }
                                  dataList={productList}
                                  onInputChange={(event, newInputValue) => {
                                    setSearchProductName(newInputValue);
                                  }}
                                  onChange={(e, v) => {
                                    if (v) {
                                      setProductNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          productItemSelected: [],
                                          searchProductItem: "",
                                        };
                                        return updatedData;
                                      });
                                      setSearchProductName("");
                                      setSearchVariantName("");
                                      onHandleProductItemSelected(index, v);
                                    } else {
                                      setResetForm(true);
                                      setVariantList([]);
                                      setSearchProductName("");
                                      setSearchVariantName("");
                                      setProductNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          productItemSelected: [],
                                          searchProductItem: "",
                                        };
                                        return updatedData;
                                      });
                                      setVariantNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          variantSelected: [],
                                          searchVariant: "",
                                        };
                                        return updatedData;
                                      });
                                      setProductOrders((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          masterProductCode: "",
                                        };
                                        return updatedData;
                                      });
                                    }
                                  }}
                                  getOptionLabel={(productList) =>
                                    productList.productName || ""
                                  }
                                  labelPicker={"Choose Product"}
                                  placeholderPicker={"search product"}
                                  sizePicker={"small"}
                                  isVisibleLabelPicker={true}
                                  labelTitlePicker={""}
                                  error={!!errors.productNameSelected}
                                  helperText={errors.productNameSelected}
                                />
                              </TableCell>
                              <TableCell width={"25%"}>
                                {resetForm ? (
                                  <></>
                                ) : (
                                  <PickerSearch
                                    disabled={
                                      productOrders[index].masterProductCode
                                        ? false
                                        : true
                                    }
                                    value={
                                      variantNameSelected[index]
                                        ?.variantSelected
                                    }
                                    dataList={variantList}
                                    onInputChange={(event, newInputValue) => {
                                      setSearchVariantName(newInputValue);
                                    }}
                                    onChange={(e, v) => {
                                      if (v) {
                                        setVariantNameSelected((prevData) => {
                                          const updatedData = [...prevData];
                                          updatedData[index] = {
                                            ...updatedData[index],
                                            variantSelected: v,
                                            searchVariant: v.variantName,
                                          };
                                          return updatedData;
                                        });
                                        onHandleVariantItemSelected(index, v);
                                      } else {
                                        setVariantNameSelected((prevData) => {
                                          const updatedData = [...prevData];
                                          updatedData[index] = {
                                            ...updatedData[index],
                                            variantSelected: [],
                                            searchVariant: "",
                                          };
                                          return updatedData;
                                        });
                                        setSearchVariantName("");
                                      }
                                    }}
                                    getOptionLabel={(variantList) =>
                                      variantList.variantName || ""
                                    }
                                    onOpen={() =>
                                      onGetVariantProduct(
                                        productOrders[index].masterProductCode
                                      )
                                    }
                                    labelPicker={"Choose Variant"}
                                    placeholderPicker={"search variant"}
                                    sizePicker={"small"}
                                    isVisibleLabelPicker={true}
                                    labelTitlePicker={""}
                                    error={!!errors.variantNameSelected}
                                    helperText={errors.variantNameSelected}
                                  />
                                )}
                              </TableCell>
                              <TableCell width={"10%"}>
                                <FormInput
                                  disabled={
                                    productOrders[index].masterProductCode
                                      ? false
                                      : true
                                  }
                                  size="small"
                                  label="Quantity"
                                  name="quantity"
                                  type="number"
                                  error={!!errors.quantity}
                                  helperText={errors.quantity}
                                  value={item.quantity}
                                  onChange={(event) => {
                                    handleInputQuantityChange(event, index);
                                    calculateTotalPrice(index);
                                  }}
                                />
                              </TableCell>
                              <TableCell width={"15%"}>
                                <FormInput
                                  disabled={true}
                                  size="small"
                                  label="Price"
                                  name="trxPrice"
                                  type="number"
                                  inputPrice={true}
                                  error={!!errors.trxPrice}
                                  helperText={errors.trxPrice}
                                  value={item.defaultTrxPrice}
                                />
                              </TableCell>
                              <TableCell width={"15%"}>
                                <FormInput
                                  disabled={true}
                                  size="small"
                                  label="Total"
                                  name="netPrice"
                                  type="number"
                                  inputPrice={true}
                                  error={!!errors.netPrice}
                                  helperText={errors.netPrice}
                                  value={item.netPrice}
                                />
                              </TableCell>
                              <TableCell width={"5%"} align="center">
                                <div className="flex flex-row gap-2 items-center justify-center">
                                  <Tooltip title="Delete Variant">
                                    <IconButton
                                      onClick={() => {
                                        setIsRemoveProduct(true);
                                        onHandleRemoveProductOrder(index);
                                      }}
                                      size="medium"
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 1,
                                        backgroundColor: "#f4e6fb",
                                        color: "#FE3133",
                                      }}
                                    >
                                      <BsFillTrash3Fill />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Modal>

      <ModalDetail
        onClose={() => setIsModalDetail(false)}
        isVisibleDetail={isModalDetail}
        transactionSelected={stockInSelected}
        detailTransaction={stockInDetail}
      />

      {/* <Modal
        modalTitle="Add Selling Price"
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Selling Price"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <FormInput
              label="Selling Price"
              name="sellingPriceInput"
              type="number"
              inputPrice={true}
              error={!!errors.sellingPriceInput}
              helperText={errors.sellingPriceInput}
              value={sellingPriceInput}
              onChange={(event) => {
                setSellingPriceInput(event.target.value);
                setErrors({});
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <PickerSearch
              dataList={statusList}
              onChange={(e, v) => {
                if (v) {
                  setStatusSelected(v);
                } else {
                  setStatusSelected("");
                }
              }}
              getOptionLabel={(statusList) => statusList.label || ""}
              labelPicker={"Choose Status"}
              placeholderPicker={"search status"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.statusSelected}
              helperText={errors.statusSelected}
            />
          </Grid>
        </Grid>
      </Modal> */}

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </Container>
  );
}

export default StockIn;
