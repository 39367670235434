import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadStockCard } from "../../utils/Constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import moment from "moment";
import ModalDetail from "../../components/ModalDetail";
import { Box, Container, Paper, Stack } from "@mui/material";

function StockCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // ==================
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ==================
  const [stockCardList, setStockCardList] = useState(null);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================
  const [productList, setProductList] = useState(null);
  const [searchProductName, setSearchProductName] = useState("");
  const [productNameSelected, setProductNameSelected] = useState([]);
  // ==================
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionType] = useState(50);
  // ==================
  const [transactionSelected, setTransactionSelected] = useState([]);
  const [detailTransaction, setDetailTransaction] = useState([]);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  // ===============

  const onGetStockCard = async () => {
    setIsLoading(true);
    const currentPage = page + 1;
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .getStockCard(
          branchCodeSelected,
          productNameSelected.length !== 0
            ? productNameSelected.productName
            : "",
          dateFromSelected,
          dateToSelected,
          rowsPerPage,
          currentPage
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            // setTotalRecord(res.data.totalData);
            setTotalRecord(res.data.totalPage * res.data.limit);
            setStockCardList(res.data.data);
            setIsLoading(false);
          } else {
            setStockCardList([]);
            onError(res.message);
          }
        })
        .catch((error) => {
          setStockCardList([]);
          onError(error.message);
        });
    } catch (error) {
      setStockCardList([]);
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchList(updatedBranchList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetProducts = async () => {
    try {
      const res = await api
        .getProduct(
          searchProductName,
          ""
          // branchNameSelected.length !== 0 ? branchNameSelected.branchCode : ""
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setProductList(res.data.data);
          } else {
            onError(res.message);
            setProductList([]);
          }
        })
        .catch((error) => {
          onError(error.message);
          setProductList([]);
        });
    } catch (error) {
      onError(error.message);
      setProductList([]);
    }
  };

  const onGetDetailTransaction = async (data) => {
    try {
      const res = await api
        .getDetailTransaction(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setDetailTransaction(res.data);
            setTransactionSelected(data);
            setIsVisibleDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onHandleReport = async () => {
    setIsSpinner(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .reportDownload(transactionCode, dateFromSelected, dateToSelected)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const fileName = "Stock-card-report";
            setIsSpinner(false);
            exportToXLSX(res.data.data, fileName);
            onSuccessSubmit();
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==============
  useEffect(() => {
    const fetch = async () => {
      await onGetStockCard();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    onGetProducts();
  }, [searchProductName]);

  const onError = async (message) => {
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const onSuccessSubmit = async () => {
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    // onGetDetailTransaction(itemSelected);
    setDetailTransaction(itemSelected);
    setIsVisibleDetail(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...stockCardList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setStockCardList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Stock Card List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={onHandleReport}
          >
            Download Report
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={2}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE OUTLET
              </Typography>
              <PickerSearch
                dataList={branchList}
                onInputChange={(event, newInputValue) => {
                  setSearchBranchName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setBranchCodeSelected(v.branchCode);
                  } else {
                    setBranchCodeSelected("");
                  }
                }}
                getOptionLabel={(branchList) => branchList.branchName || ""}
                labelPicker="Choose Outlet"
                placeholderPicker="search outlet"
                sizePicker="small"
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE PRODUCT
              </Typography>
              <PickerSearch
                dataList={productList}
                onInputChange={(event, newInputValue) => {
                  setSearchProductName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setProductNameSelected(v);
                  } else {
                    setProductNameSelected([]);
                  }
                }}
                getOptionLabel={(product) => product.productName || ""}
                labelPicker={"Choose Product"}
                placeholderPicker={"search product"}
                sizePicker={"small"}
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE FROM
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date From"
                dateValue={selectedDateFrom}
                onChangeDate={(val) => setSelectedDateFrom(val)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE TO
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date To"
                dateValue={selectedDateTo}
                onChangeDate={(val) => setSelectedDateTo(val)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                onGetStockCard();
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="stock-card-list"
          tableName={"Stock Card"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadStockCard}
          data={stockCardList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
          handleRequestSort={handleRequestSort}
        />
      </Paper>

      <ModalDetail
        type="history-detail"
        fullScreen={true}
        onClose={() => setIsVisibleDetail(false)}
        isVisibleDetail={isVisibleDetail}
        detailProduct={detailTransaction}
      />
    </Container>
  );
}

export default StockCard;
