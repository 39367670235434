import React, { useEffect, useState } from "react";
import api from "../../helpers/api";
import {
  TableList,
  Spinner,
  Modal,
  FormInput,
  PickerSearch,
} from "../../components";
import { Button, Grid, Typography } from "@mui/material";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { tableHeadBranch, statusList } from "../../utils/Constants";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SearchOutlined } from "@mui/icons-material";
import { Box, Container, Paper, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function Branches() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  // ==========
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ===========
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  // ==========
  const [branchNameInput, setBranchNameInput] = useState("");
  const [picNameInput, setPicNameInput] = useState("");
  const [branchPhoneInput, setBranchPhoneInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [statusSelected, setStatusSelected] = useState([]);
  const [branchSelected, setBranchSelected] = useState([]);
  // ==========

  const onGetBranches = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api
        .getBranch(searchBranchName, rowsPerPage, currentPage)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setBranchList(res.data.data);
            setTotalRecord(res.data.totalPage * res.data.limit);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onAddBranch = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveBranch(
          branchNameInput,
          picNameInput,
          statusSelected.label,
          statusSelected.value,
          branchPhoneInput,
          addressInput
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onEditBranch = async () => {
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .editBranch(
          branchSelected.id,
          branchNameInput,
          picNameInput,
          statusSelected.label,
          statusSelected.value,
          branchPhoneInput,
          addressInput
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  // ==========

  useEffect(() => {
    const fetch = async () => {
      await onGetBranches();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetBranches();
    }
  }, [isReload]);

  // ==========

  const onSuccessSubmit = async () => {
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
    setBranchNameInput("");
    setPicNameInput("");
    setBranchPhoneInput("");
    setAddressInput("");
    setStatusSelected([]);
  };

  const onError = async (message) => {
    setIsSpinner(false);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
    setIsReload(false);
  };

  const onModalClose = () => {
    setBranchNameInput("");
    setPicNameInput("");
    setBranchPhoneInput("");
    setAddressInput("");
    setStatusSelected([]);
    setIsOpenModal(false);
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
    if (!branchNameInput || branchNameInput.trim() === "") {
      newErrors.branchNameInput = "Branch Name is required";
    }
    if (!picNameInput || picNameInput.trim() === "") {
      newErrors.picNameInput = "PIC is required";
    }
    if (statusSelected.length === 0) {
      newErrors.statusSelected = "Status is required";
    }
    if (!branchPhoneInput || branchPhoneInput.trim() === "") {
      newErrors.branchPhoneInput = "Phone is required";
    }
    if (!addressInput || addressInput.trim() === "") {
      newErrors.addressInput = "Address is required";
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (mode === "add") {
      await onAddBranch();
    } else if (mode === "edit") {
      await onEditBranch();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditItem = (itemSelected, indexSelected) => {
    const status = statusList.filter(
      (i) => i.value === itemSelected.statusCode
    );
    setMode("edit");
    setBranchSelected(itemSelected);
    setBranchNameInput(itemSelected.branchName);
    setPicNameInput(itemSelected.supplierContacName);
    setStatusSelected(status[0]);
    setBranchPhoneInput(itemSelected.branchPhone);
    setAddressInput(itemSelected.address);
    setIsOpenModal(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...branchList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setBranchList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // =========

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />
      {/* <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Outlet List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("add");
              setIsOpenModal(true);
            }}
          >
            Add New Outlet
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <FormInput
              size="small"
              label="Search Outlet Name"
              name="searchBranchName"
              value={searchBranchName}
              onChange={(event) => setSearchBranchName(event.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<SearchOutlined />}
              onClick={() => {
                setIsReload(true);
              }}
              sx={{
                marginTop: 1,
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableList
          tableStructure="outlet-list"
          tableName={"Outlet List"}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadBranch}
          data={branchList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={handleEditItem}
        />
      </div> */}

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Outlet List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setMode("add");
              setIsOpenModal(true);
            }}
          >
            Add New Outlet
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                OUTLET NAME
              </Typography>
              <FormInput
                size="small"
                label="Search Outlet Name"
                name="searchBranchName"
                value={searchBranchName}
                onChange={(event) => setSearchBranchName(event.target.value)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                setIsReload(true);
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="outlet-list"
          tableName={"Outlet List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadBranch}
          data={branchList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={handleEditItem}
          handleRequestSort={handleRequestSort}
        />
      </Paper>

      <Modal
        modalTitle={`${mode === "edit" ? "Edit Outlet" : "Add New Outlet"}`}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Outlet"}
        onPressCancel={() => {
          onModalClose();
        }}
        onPressSave={handleSubmit}
      >
        <FormInput
          label="Outlet Name"
          name="branchNameInput"
          error={!!errors.branchNameInput}
          helperText={errors.branchNameInput}
          value={branchNameInput}
          onChange={(event) => {
            setBranchNameInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="PIC Name"
          name="picNameInput"
          error={!!errors.picNameInput}
          helperText={errors.picNameInput}
          value={picNameInput}
          onChange={(event) => {
            setPicNameInput(event.target.value);
            setErrors({});
          }}
        />

        <PickerSearch
          value={statusSelected}
          dataList={statusList}
          onChange={(e, v) => {
            if (v) {
              setStatusSelected(v);
            } else {
              setStatusSelected("");
            }
          }}
          getOptionLabel={(statusList) => statusList.label || ""}
          labelPicker={"Choose Status"}
          placeholderPicker={"choose status"}
          sizePicker={"large"}
          isVisibleLabelPicker={true}
          labelTitlePicker={""}
          error={!!errors.statusSelected}
          helperText={errors.statusSelected}
        />

        <FormInput
          label="Phone"
          name="branchPhoneInput"
          error={!!errors.branchPhoneInput}
          helperText={errors.branchPhoneInput}
          value={branchPhoneInput}
          onChange={(event) => {
            setBranchPhoneInput(event.target.value);
            setErrors({});
          }}
        />

        <FormInput
          label="Address"
          name="addressInput"
          error={!!errors.addressInput}
          helperText={errors.addressInput}
          value={addressInput}
          onChange={(event) => {
            setAddressInput(event.target.value);
            setErrors({});
          }}
        />
      </Modal>

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </Container>
  );
}
