import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { validateFormInputSellingPrice } from "../../helpers/functions/validateTextInput";
import api from "../../helpers/api";
import { tableHeadStockOut, tableHeadAddStockOut } from "../../utils/Constants";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { BsFillTrash3Fill } from "react-icons/bs";
import ModalDetail from "../../components/ModalDetail";
import { Box, Container, Paper, Stack } from "@mui/material";

function StockOut() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isModalDetail, setIsModalDetail] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ==================
  const [stockOutList, setstockOutList] = useState([]);
  const [stockOutSelected, setStockOutSelected] = useState("");
  const [stockOutDetail, setStockOutDetail] = useState([]);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================

  const [sellingPriceInput, setSellingPriceInput] = useState("");
  const [statusSelected, setStatusSelected] = useState([]);
  // ==================
  const [isRemoveProduct, setIsRemoveProduct] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [dateStockOut, setdateStockOut] = useState(null);
  const [note, setNote] = useState("");
  const [productOrders, setProductOrders] = useState([]);
  // ==================
  const [productList, setProductList] = useState([]);
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  // ==================
  const [variantList, setVariantList] = useState([]);
  const [variantNameSelected, setVariantNameSelected] = useState([]);
  const [searchVariantName, setSearchVariantName] = useState("");
  const [additional, setAdditional] = useState({
    serviceCharge: "0",
    taxCharge: "0",
    taxResto: "0",
    cashPayment: 0,
    edcPayment: 0,
    onlinePayment: 0,
    rounding: 0,
    netPayment: 0,
    discount: 0,
    additionalCost: 0,
    paymentType: "Cash",
    paymentTypeCode: 456,
    transactionType: "STOCK OUT",
    transactionTypeCode: 311,
  });
  // ==================

  const onGetStockOutList = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api
        .getStockOutData(
          branchCodeSelected,
          searchProductName,
          rowsPerPage,
          currentPage
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setstockOutList(res.data.data);
            setTotalRecord(res.data.totalPage * res.data.limit);
            // setTotalRecord(res.data.totalData);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchList(updatedBranchList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetProducts = async () => {
    try {
      const res = await api
        .getInventoryData(
          branchNameSelected.length !== 0 ? branchNameSelected.branchCode : "",
          searchProductName
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setProductList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onCheckoutProuct = async () => {
    const attachmentDataOrder = productOrders.map((productCO) => {
      return {
        id: productCO.productId,
        quantity: productCO.requestQuantity,
      };
    });
    console.log("body checkout", attachmentDataOrder);
    try {
      const res = await api
        .checkoutStockOut(
          branchNameSelected.branchCode,
          branchNameSelected.branchName,
          attachmentDataOrder
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            const verifyData = res.data.attachments;
            const modifyDataProductsCheckout = verifyData.map((product) => {
              const updatedProduct = {
                ...product,
                totalProductItemCost: product.netPrice,
                isAddOn: false,
              };
              delete updatedProduct.id;
              return updatedProduct;
            });
            // console.log('checkout', JSON.stringify(modifyDataProductsCheckout));
            const dataOrder = await Promise.all(modifyDataProductsCheckout);
            saveAddStockOut(dataOrder);
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onGetDetailStockOut = async (data) => {
    setIsSpinner(true);
    try {
      const res = await api
        .getDetailStock(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            setStockOutDetail(res.data);
            setStockOutSelected(data);
            setIsModalDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const saveAddStockOut = async (stockOutCoList) => {
    console.log("stock out save", stockOutCoList);
    const sumTotal = stockOutCoList.reduce(
      (accumulator, current) => accumulator + parseInt(current.netPrice),
      0
    );
    setIsOpenModal(false);
    setIsSpinner(true);
    const bodyStockOut = {
      transactionFromCode: branchNameSelected.branchCode,
      transactionFromName: branchNameSelected.branchName,
      transactionToCode: branchNameSelected.branchCode,
      transactionToName: branchNameSelected.branchName,
      note: note,
      TaCharge: additional.taxCharge,
      grabFood: additional.onlinePayment,
      rounding: additional.rounding,
      taxResto: additional.taxResto,
      edcPayment: additional.edcPayment,
      cashPayment: sumTotal,
      paymentType: "CASH",
      serviceCharge: additional.serviceCharge,
      additionalCost: additional.additionalCost,
      paymentTypeCode: additional.paymentTypeCode,
      transactionDate: dateStockOut,
      attachments: stockOutCoList,
    };
    console.log("bodyStockOut", bodyStockOut);
    try {
      const res = await api
        .saveStockOut(
          branchNameSelected.branchCode,
          branchNameSelected.branchName,
          note,
          additional.taxCharge,
          additional.onlinePayment,
          additional.rounding,
          additional.taxResto,
          additional.edcPayment,
          sumTotal,
          "CASH",
          additional.serviceCharge,
          additional.additionalCost,
          additional.paymentTypeCode,
          dateStockOut,
          stockOutCoList
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  // ===================
  useEffect(() => {
    const fetch = async () => {
      await onGetStockOutList();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetStockOutList();
    }
  }, [isReload]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    onGetProducts();
  }, [searchProductName, branchNameSelected]);

  const onSuccessSubmit = async () => {
    setBranchNameSelected([]);
    setSearchBranchName("");
    setNote("");
    setdateStockOut(null);
    setProductOrders([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const onCloseModal = () => {
    setErrors({});
    setIsOpenModal(false);
  };

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDetailStockOut = (itemSelected, indexSelected) => {
    onGetDetailStockOut(itemSelected);
  };

  const handleAddProduct = () => {
    setSearchProductName("");
    setProductOrders([
      ...productOrders,
      {
        variantId: "",
        note: "",
        brandId: 0,
        masterProductCode: "",
        variantName: "",
        imageUrl: "",
        netPrice: 0,
        quantity: 0,
        trxPrice: 0,
        brandName: "",
        productId: "",
        categoryId: 0,
        productSku: "",
        productCode: "",
        productName: "",
        categoryName: "",
        updatedQuantity: 0,
        defaultTrxPrice: "",
        basePrice: "",
        requestQuantity: 0,
      },
    ]);
  };

  const onHandleProductItemSelected = (currentIndex, itemSelector) => {
    const newProducts = [...productOrders];
    newProducts[currentIndex].note = "";
    newProducts[currentIndex].brandId = itemSelector.brandId;
    newProducts[currentIndex].imageUrl = itemSelector.imageUrl;
    newProducts[currentIndex].brandName = itemSelector.brandName;
    newProducts[currentIndex].productId = itemSelector.id;
    newProducts[currentIndex].categoryId = itemSelector.categoryId;
    newProducts[currentIndex].productName = itemSelector.productName;
    newProducts[currentIndex].categoryName = itemSelector.categoryName;
    newProducts[currentIndex].updatedQuantity = itemSelector.updatedQuantity;
    newProducts[currentIndex].masterProductCode =
      itemSelector.masterProductCode;

    newProducts[currentIndex].variantName = itemSelector.variantName;
    newProducts[currentIndex].productSku = itemSelector.productSku;
    newProducts[currentIndex].productCode = itemSelector.productCode;
    newProducts[currentIndex].defaultTrxPrice = itemSelector.trxPrice;
    newProducts[currentIndex].basePrice = itemSelector.trxPrice;

    newProducts[currentIndex].netPrice = 0;
    newProducts[currentIndex].quantity = 0;
    newProducts[currentIndex].trxPrice = 0;

    setProductOrders(newProducts);
    setProductNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        productItemSelected: itemSelector,
        searchProductItem: itemSelector.variantName,
      };
      return updatedData;
    });
    setVariantNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        variantSelected: [],
        searchVariant: "",
      };
      return updatedData;
    });
  };

  const handleInputQuantityChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    newProducts[index].requestQuantity = event.target.value;
    setProductOrders(newProducts);
  };

  const calculateTotalPrice = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].netPrice =
      newProducts[index].requestQuantity * newProducts[index].defaultTrxPrice;
    setProductOrders(newProducts);
  };

  const onHandleRemoveProductOrder = (index) => {
    const newArray = [...productOrders];
    const newArrayProductSelected = [...productNameSelected];
    const newArrayVariantSelected = [...variantNameSelected];

    newArray.splice(index, 1);
    newArrayProductSelected.splice(index, 1);
    newArrayVariantSelected.splice(index, 1);

    setProductOrders(newArray);
    setProductNameSelected(newArrayProductSelected);
    setVariantNameSelected(newArrayVariantSelected);
    setSearchProductName("");

    if (productOrders.length === 0) {
      setProductNameSelected([]);
      setVariantNameSelected([]);
    }

    setTimeout(() => {
      setIsRemoveProduct(false);
    }, 300);
  };

  const validateForm = () => {
    const newErrors = {};
    if (
      !branchNameSelected ||
      branchNameSelected === null ||
      branchNameSelected.length === 0
    ) {
      newErrors.branchNameSelected = "Branch / Outlet is required to choose";
    }

    if (dateStockOut === null) {
      newErrors.dateStockOut = "Date must be fill";
    }
    return newErrors;
  };

  const validateFormProductOrder = () => {
    const hasEmptyFields = productOrders.some(
      (productItem) =>
        !productItem.masterProductCode ||
        !productItem.requestQuantity ||
        !productItem.netPrice
    );

    if (productOrders.length === 0) {
      onError("Product Order must be added first...");
      return false;
    }

    if (hasEmptyFields) {
      onError(
        "Please ensure all product order have Product Name, Variant Name, Quantity, Price & Total filled."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const isValid = validateFormProductOrder();
    if (isValid) {
      onCheckoutProuct();
      // saveAddStockOut();
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...stockOutList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setstockOutList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Stock Out List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(!isOpenModal);
            }}
          >
            Add Stock Out
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE OUTLET
              </Typography>
              <PickerSearch
                dataList={branchList}
                onInputChange={(event, newInputValue) => {
                  setSearchBranchName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setBranchCodeSelected(v.branchCode);
                  } else {
                    setBranchCodeSelected("");
                  }
                }}
                getOptionLabel={(branchList) => branchList.branchName || ""}
                labelPicker="Choose Outlet"
                placeholderPicker="search outlet"
                sizePicker="small"
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE FROM
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date From"
                dateValue={selectedDateFrom}
                onChangeDate={(val) => setSelectedDateFrom(val)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE TO
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date To"
                dateValue={selectedDateTo}
                onChangeDate={(val) => setSelectedDateTo(val)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                onGetStockOutList();
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="stockout-list"
          tableName={"Stock Out List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadStockOut}
          data={stockOutList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handleDetailStockOut}
          handleRequestSort={handleRequestSort}
        />
      </Paper>

      {/* <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Stock Out List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(!isOpenModal);
            }}
          >
            Add Stock Out
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetStockOutList();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableList
          tableStructure="stockout-list"
          tableName={"Stock Out List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadStockOut}
          data={stockOutList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handleDetailStockOut}
        />
      </div> */}

      <Modal
        fullScreen={true}
        modalTitle={"Add New Stock Out"}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Stock Out"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchNameSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameSelected(v);
                } else {
                  setBranchNameSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Branch / Outlet"}
              placeholderPicker={"search branch / outlet"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameSelected}
              helperText={errors.branchNameSelected}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormDatePicker
              labelFormDatePicker="Date Stock Out"
              formSize={"large"}
              dateValue={dateStockOut}
              onChangeDate={(val) => setdateStockOut(val)}
              sx={{ marginTop: 2 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormInput
              label="Note"
              name="note"
              multiline
              rows={3}
              error={!!errors.note}
              helperText={errors.note}
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
                setErrors({});
              }}
              sx={{ marginTop: 2 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Button
              sx={{ marginTop: "15px", marginBottom: "15px" }}
              variant="outlined"
              onClick={() => handleAddProduct()}
              startIcon={<AddCircleIcon />}
            >
              Select Product
            </Button>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableContainer mt={1}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeadAddStockOut.map((item) => {
                      return (
                        <TableCell
                          // colSpan={item.size}
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#060270",
                            color: "white",
                            width: item.size,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                {isRemoveProduct == false ? (
                  <TableBody>
                    {productOrders.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={tableHeadAddStockOut.length}
                          align="center"
                          sx={{ py: 4 }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item>
                              <AddCircleIcon
                                color="secondary"
                                fontSize="large"
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                sx={{ color: "text.secondary" }}
                              >
                                {` No product found. You can add a new product using the
                        button above.`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {productOrders.map((item, index) => {
                          return (
                            <TableRow key={item.id}>
                              <TableCell width={"5%"}>{index + 1}</TableCell>
                              <TableCell width={"25%"}>
                                <PickerSearch
                                  value={
                                    productNameSelected[index]
                                      ?.productItemSelected
                                  }
                                  dataList={productList}
                                  onInputChange={(event, newInputValue) => {
                                    setSearchProductName(newInputValue);
                                  }}
                                  onChange={(e, v) => {
                                    if (v) {
                                      setProductNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          productItemSelected: [],
                                          searchProductItem: "",
                                        };
                                        return updatedData;
                                      });
                                      setSearchProductName("");
                                      setSearchVariantName("");
                                      onHandleProductItemSelected(index, v);
                                    } else {
                                      setResetForm(true);
                                      setVariantList([]);
                                      setSearchProductName("");
                                      setSearchVariantName("");
                                      setProductNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          productItemSelected: [],
                                          searchProductItem: "",
                                        };
                                        return updatedData;
                                      });
                                      setVariantNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          variantSelected: [],
                                          searchVariant: "",
                                        };
                                        return updatedData;
                                      });
                                      setProductOrders((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          masterProductCode: "",
                                        };
                                        return updatedData;
                                      });
                                    }
                                  }}
                                  getOptionLabel={(productList) =>
                                    productList.productName || ""
                                  }
                                  labelPicker={"Choose Product"}
                                  placeholderPicker={"search product"}
                                  sizePicker={"small"}
                                  isVisibleLabelPicker={true}
                                  labelTitlePicker={""}
                                  error={!!errors.productNameSelected}
                                  helperText={errors.productNameSelected}
                                />
                              </TableCell>
                              <TableCell width={"10%"}>
                                <FormInput
                                  disabled={
                                    productOrders[index].masterProductCode
                                      ? false
                                      : true
                                  }
                                  size="small"
                                  label="Quantity"
                                  name="quantity"
                                  type="number"
                                  error={!!errors.requestQuantity}
                                  helperText={errors.requestQuantity}
                                  value={item.requestQuantity}
                                  onChange={(event) => {
                                    handleInputQuantityChange(event, index);
                                    calculateTotalPrice(index);
                                  }}
                                />
                              </TableCell>
                              <TableCell width={"15%"}>
                                <FormInput
                                  disabled={true}
                                  size="small"
                                  label="Price"
                                  name="trxPrice"
                                  type="number"
                                  inputPrice={true}
                                  error={!!errors.trxPrice}
                                  helperText={errors.trxPrice}
                                  value={item.defaultTrxPrice}
                                />
                              </TableCell>
                              <TableCell width={"15%"}>
                                <FormInput
                                  disabled={true}
                                  size="small"
                                  label="Total"
                                  name="netPrice"
                                  type="number"
                                  inputPrice={true}
                                  error={!!errors.netPrice}
                                  helperText={errors.netPrice}
                                  value={item.netPrice}
                                />
                              </TableCell>
                              <TableCell width={"5%"} align="center">
                                <div className="flex flex-row gap-2 items-center justify-center">
                                  <Tooltip title="Delete Variant">
                                    <IconButton
                                      onClick={() => {
                                        setIsRemoveProduct(true);
                                        onHandleRemoveProductOrder(index);
                                      }}
                                      size="medium"
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 1,
                                        backgroundColor: "#f4e6fb",
                                        color: "#FE3133",
                                      }}
                                    >
                                      <BsFillTrash3Fill />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Modal>

      <ModalDetail
        onClose={() => setIsModalDetail(false)}
        isVisibleDetail={isModalDetail}
        transactionSelected={stockOutSelected}
        detailTransaction={stockOutDetail}
      />

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </Container>
  );
}

export default StockOut;
