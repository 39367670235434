import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadPurchases, tableHeadPO } from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import ModalDetail from "../../components/ModalDetail";
import { Box, Container, Paper, Stack } from "@mui/material";

function Purchases() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ==================
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // ==================
  const [purchaseList, setPurchaseList] = useState([]);
  const [searchPurchaseNo, setSearchPurchaseNo] = useState("");
  const [transactionSelected, setTransactionSelected] = useState([]);
  const [detailTransaction, setDetailTransaction] = useState([]);
  // ==================
  const [supplierList, setSupplierList] = useState([]);
  const [searchSupplierName, setSearchSupplierName] = useState("");
  const [supplierNameSelected, setSupplierNameSelected] = useState([]);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  // ==================
  const [productList, setProductList] = useState([]);
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  // ==================
  const [variantList, setVariantList] = useState([]);
  const [variantNameSelected, setVariantNameSelected] = useState([]);
  const [searchVariantName, setSearchVariantName] = useState("");
  // ==================
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [isRemoveProduct, setIsRemoveProduct] = useState(false);
  const [datePurchase, setDatePurchase] = useState(null);
  const [resetForm, setResetForm] = useState(false);
  const [notes, setNotes] = useState("");
  const [productOrders, setProductOrders] = useState([]);
  const [additional, setAdditional] = useState({
    serviceCharge: "0",
    taxCharge: "0",
    taxResto: "0",
    cashPayment: 0,
    edcPayment: 0,
    rounding: 0,
    netPayment: 0,
    discount: 0,
    additionalCost: 0,
    paymentType: "Cash",
    paymentTypeCode: 456,
    transactionType: "PURCHASE ORDER",
    transactionTypeCode: 10,
  });
  // ==================

  const onGetPurchases = async () => {
    const currentPage = page + 1;
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    setIsLoading(true);
    try {
      const res = await api
        .getPurchaseDataList(
          currentPage,
          rowsPerPage,
          searchPurchaseNo,
          dateFromSelected,
          dateToSelected,
          branchCodeSelected
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setTotalRecord(res.data.totalPage * res.data.limit);
            setPurchaseList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetVariantProduct = async (masterProductCode) => {
    try {
      const res = await api
        .getProductVariantList(masterProductCode)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setVariantList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetSupplier = async () => {
    try {
      const res = await api
        .getSupplier(searchSupplierName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setSupplierList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setBranchList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetProducts = async () => {
    try {
      const res = await api
        .getProduct(
          searchProductName,
          branchNameSelected.length !== 0 ? branchNameSelected.branchCode : ""
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setProductList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const getDetailPurchase = async (data) => {
    setIsSpinner(true);
    try {
      const res = await api
        .getDetailPurchase(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            setDetailTransaction(res.data);
            setTransactionSelected(data);
            setIsVisibleDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const savePurchaseOrder = async () => {
    const sumTotal = productOrders.reduce(
      (accumulator, current) => accumulator + parseInt(current.netPrice),
      0
    );
    setIsOpenModal(false);
    setIsSpinner(true);
    const bodyPo = {
      item: productOrders.length,
      note: notes,
      TaCharge: additional.taxCharge,
      discount: additional.discount,
      rounding: additional.rounding,
      taxResto: additional.taxResto,
      edcPayment: additional.edcPayment,
      netPayment: sumTotal,
      cashPayment: sumTotal,
      paymentType: additional.paymentType,
      serviceCharge: additional.serviceCharge,
      additionalCost: additional.additionalCost,
      paymentTypeCode: additional.paymentTypeCode,
      transactionType: additional.transactionType,
      transactionToCode: branchNameSelected.branchCode,
      transactionToName: branchNameSelected.branchName,
      transactionFromCode: supplierNameSelected.supplierCode,
      transactionFromName: supplierNameSelected.supplierName,
      transactionTypeCode: additional.transactionTypeCode,
      attachments: productOrders,
    };
    console.log("bodyPo", bodyPo);
    try {
      const res = await api
        .savePurchaseOrder(
          datePurchase,
          productOrders.length,
          notes,
          additional.taxCharge,
          additional.discount,
          additional.rounding,
          additional.taxResto,
          additional.edcPayment,
          sumTotal,
          sumTotal,
          additional.paymentType,
          additional.serviceCharge,
          additional.additionalCost,
          additional.paymentTypeCode,
          additional.transactionType,
          branchNameSelected.branchCode,
          branchNameSelected.branchName,
          supplierNameSelected.supplierCode,
          supplierNameSelected.supplierName,
          additional.transactionTypeCode,
          productOrders
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  // ==================

  useEffect(() => {
    const fetch = async () => {
      await onGetPurchases();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetPurchases();
    }
  }, [isReload]);

  useEffect(() => {
    onGetSupplier();
  }, [searchSupplierName]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    onGetProducts();
  }, [searchProductName]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const onSuccessSubmit = async () => {
    setBranchNameSelected([]);
    setSearchBranchName("");
    setSupplierNameSelected([]);
    setSearchSupplierName("");
    setNotes("");
    setProductOrders([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setDatePurchase(null);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const onCloseModal = async () => {
    setBranchNameSelected([]);
    setSearchBranchName("");
    setSupplierNameSelected([]);
    setSearchSupplierName("");
    setNotes("");
    setProductOrders([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setMode("");
    setErrors({});
    setIsOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddProduct = () => {
    setSearchProductName("");
    setProductOrders([
      ...productOrders,
      {
        note: "",
        brandId: 0,
        masterProductCode: "",
        variantName: "",
        imageUrl: "",
        netPrice: 0,
        quantity: 0,
        trxPrice: 0,
        brandName: "",
        productId: "",
        categoryId: 0,
        productSku: "",
        productCode: "",
        productName: "",
        categoryName: "",
        updatedQuantity: 0,
        defaultTrxPrice: "",
      },
    ]);
  };

  const onHandleProductItemSelected = (currentIndex, itemSelector) => {
    const newProducts = [...productOrders];
    newProducts[currentIndex].note = "";
    newProducts[currentIndex].brandId = itemSelector.brandId;
    newProducts[currentIndex].imageUrl = itemSelector.imageUrl;
    newProducts[currentIndex].brandName = itemSelector.brandName;
    newProducts[currentIndex].productId = itemSelector.id;
    newProducts[currentIndex].categoryId = itemSelector.categoryId;
    newProducts[currentIndex].productName = itemSelector.productName;
    newProducts[currentIndex].categoryName = itemSelector.categoryName;
    newProducts[currentIndex].updatedQuantity = itemSelector.updatedQuantity;
    newProducts[currentIndex].masterProductCode =
      itemSelector.masterProductCode;

    newProducts[currentIndex].variantName = "";
    newProducts[currentIndex].productSku = "";
    newProducts[currentIndex].productCode = "";

    newProducts[currentIndex].netPrice = 0;
    newProducts[currentIndex].quantity = 0;
    newProducts[currentIndex].trxPrice = 0;

    setProductOrders(newProducts);
    setProductNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        productItemSelected: itemSelector,
        searchProductItem: itemSelector.variantName,
      };
      return updatedData;
    });
    setVariantNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        variantSelected: [],
        searchVariant: "",
      };
      return updatedData;
    });
  };

  const onHandleVariantItemSelected = (currentIndex, itemSelector) => {
    const duplicateProduct = productOrders.filter(
      (x) => x.productId === itemSelector.id
    );
    if (duplicateProduct.length == 0) {
      const newProducts = [...productOrders];
      newProducts[currentIndex].variantName = itemSelector.variantName;
      newProducts[currentIndex].productSku = itemSelector.productSku;
      newProducts[currentIndex].productCode = itemSelector.productCode;
      newProducts[currentIndex].defaultTrxPrice = itemSelector.defaultTrxPrice;

      setProductOrders(newProducts);
    }
  };

  const handleInputQuantityChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    newProducts[index].updatedQuantity = event.target.value;
    setProductOrders(newProducts);
  };

  const handleInputChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    setProductOrders(newProducts);
  };

  const calculateTotalPrice = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].netPrice =
      newProducts[index].quantity * newProducts[index].trxPrice;
    setProductOrders(newProducts);
  };

  const calculatePriceItem = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].trxPrice =
      newProducts[index].netPrice / newProducts[index].quantity;
    setProductOrders(newProducts);
  };

  const onHandleRemoveProductOrder = (index) => {
    const newArray = [...productOrders];
    const newArrayProductSelected = [...productNameSelected];
    const newArrayVariantSelected = [...variantNameSelected];

    newArray.splice(index, 1);
    newArrayProductSelected.splice(index, 1);
    newArrayVariantSelected.splice(index, 1);

    setProductOrders(newArray);
    setProductNameSelected(newArrayProductSelected);
    setVariantNameSelected(newArrayVariantSelected);
    setSearchProductName("");

    if (productOrders.length === 0) {
      setProductNameSelected([]);
      setVariantNameSelected([]);
    }

    setTimeout(() => {
      setIsRemoveProduct(false);
    }, 300);
  };

  const validateForm = () => {
    const newErrors = {};
    if (
      !supplierNameSelected ||
      supplierNameSelected === null ||
      supplierNameSelected.length === 0
    ) {
      newErrors.supplierNameSelected = "Supplier is required to choose";
    }

    if (
      !branchNameSelected ||
      branchNameSelected === null ||
      branchNameSelected.length === 0
    ) {
      newErrors.branchNameSelected = "Branch / Outlet is required to choose";
    }

    if (datePurchase === null) {
      newErrors.datePurchase = "Date must be fill";
    }
    return newErrors;
  };

  const validateFormProductOrder = () => {
    const hasEmptyFields = productOrders.some(
      (productItem) =>
        !productItem.masterProductCode ||
        !productItem.variantName ||
        !productItem.quantity ||
        !productItem.trxPrice ||
        !productItem.netPrice
    );

    if (productOrders.length === 0) {
      onError("Product Order must be added first...");
      return false;
    }

    if (hasEmptyFields) {
      onError(
        "Please ensure all product order have Product Name, Variant Name, Quantity, Price & Total filled."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const isValid = validateFormProductOrder();
    if (isValid) {
      savePurchaseOrder();
    }
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    getDetailPurchase(itemSelected);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...purchaseList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setPurchaseList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Purchases List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add New Purchases
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE OUTLET
              </Typography>
              <PickerSearch
                dataList={branchList}
                onInputChange={(event, newInputValue) => {
                  setSearchBranchName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setBranchCodeSelected(v.branchCode);
                  } else {
                    setBranchCodeSelected("");
                  }
                }}
                getOptionLabel={(branchList) => branchList.branchName || ""}
                labelPicker="Choose Outlet"
                placeholderPicker="search outlet"
                sizePicker="small"
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE FROM
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date From"
                dateValue={selectedDateFrom}
                onChangeDate={(val) => setSelectedDateFrom(val)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                DATE TO
              </Typography>
              <FormDatePicker
                labelFormDatePicker="Date To"
                dateValue={selectedDateTo}
                onChangeDate={(val) => setSelectedDateTo(val)}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                onGetPurchases();
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="purchases-list"
          tableName={"Purchases List"}
          orderBy={orderBy}
          order={order}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadPurchases}
          data={purchaseList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
          handleRequestSort={handleRequestSort}
        />
      </Paper>

      {/* <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Purchases List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add New Purchases
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={4}>
            <h4 className="font-semibold mb-2 text-xs">DATE FROM</h4>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={(val) => setSelectedDateFrom(val)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <h4 className="font-semibold mb-2 text-xs">DATE TO</h4>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={(val) => setSelectedDateTo(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetPurchases();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableList
          tableStructure="purchases-list"
          tableName={"Purchases List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadPurchases}
          data={purchaseList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
        />
      </div> */}
    </Container>
  );
}

export default Purchases;
