import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainLayout } from "../components";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";

import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import LiquorRoundedIcon from "@mui/icons-material/LiquorRounded";
import LocalConvenienceStoreRoundedIcon from "@mui/icons-material/LocalConvenienceStoreRounded";

import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import Groups3RoundedIcon from "@mui/icons-material/Groups3Rounded";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  Dashboard,
  Products,
  Brands,
  Categories,
  Supplier,
  Inventory,
  Transaction,
  Purchases,
  Branches,
  Users,
  Balance,
  Income,
  Expenses,
  CurrentStock,
  DailyProvit,
  ProductProvit,
  ProductReceipt,
  StockIn,
  StockOut,
  StockOpname,
  StockCard,
  AddOns,
  Login,
} from "../pages";
import PrivateRoute from "../contexts/PrivateRoute";
import { getRole } from "../utils/auth";
import AuthContext from "../contexts/AuthProvider";

// Navigation configuration that matches MainLayout
const navigationConfig = {
  mainNavItems: [{ title: "Dashboard", path: "/" }],
  reportNavItems: [
    {
      title: "Balance",
      path: "/balance",
      icon: <TableViewIcon />,
    },
    { title: "Sales", path: "/transaction", icon: <BarChartOutlinedIcon /> },
    { title: "Daily Report", path: "/dailyProvit", icon: <TrendingUpIcon /> },
    {
      title: "Product Profit",
      path: "/productProvit",
      icon: <LocalMallIcon />,
    },
    {
      title: "Current Stock",
      path: "/currentStock",
      icon: <MoveToInboxIcon />,
    },
  ],
  inventoryNavItems: [
    { title: "Stock Card", path: "/stockCard", icon: <WarehouseIcon /> },
    {
      title: "All Products",
      path: "/products",
      icon: <LiquorRoundedIcon />,
    },
    {
      title: "Purchase Order",
      path: "/purchases",
      icon: <ShoppingBasketRoundedIcon />,
    },
    {
      title: "Product Receipt",
      path: "/productReceipt",
      icon: <ReceiptIcon />,
    },
    { title: "Stock In", path: "/stockIn", icon: <MoveDownIcon /> },
    { title: "Stock Out", path: "/stockOut", icon: <MoveUpIcon /> },
    {
      title: "Transfer Stock",
      path: "/inventory",
      icon: <SwapHorizIcon />,
    },
    {
      title: "Stock Opname",
      path: "/stockOpname",
      icon: <ManageHistoryIcon />,
    },
  ],
  managementNavItems: [
    {
      title: "Categories",
      path: "/categories",
      icon: <ClassOutlinedIcon />,
    },
    {
      title: "Suppliers",
      path: "/supplier",
      icon: <LocalShippingRoundedIcon />,
    },
    {
      title: "Outlets",
      path: "/branches",
      icon: <LocalConvenienceStoreRoundedIcon />,
    },
    { title: "Users", path: "/users", icon: <Groups3RoundedIcon /> },
  ],
};

export function Routers() {
  // const userRole = getRole();
  const { authData } = useContext(AuthContext);
  const userRole = authData.role;
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout navigationConfig={navigationConfig} />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          {userRole === "admin" || userRole === "owner" ? (
            <>
              <Route
                path="/products"
                element={
                  <PrivateRoute>
                    <Products />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Brands"
                element={
                  <PrivateRoute>
                    <Brands />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Categories"
                element={
                  <PrivateRoute>
                    <Categories />
                  </PrivateRoute>
                }
              />
              <Route
                path="/Supplier"
                element={
                  <PrivateRoute>
                    <Supplier />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Inventory"
                element={
                  <PrivateRoute>
                    <Inventory />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Transaction"
                element={
                  <PrivateRoute>
                    <Transaction />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Purchases"
                element={
                  <PrivateRoute>
                    <Purchases />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Branches"
                element={
                  <PrivateRoute>
                    <Branches />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Balance"
                element={
                  <PrivateRoute>
                    <Balance />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Income"
                element={
                  <PrivateRoute>
                    <Income />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Expenses"
                element={
                  <PrivateRoute>
                    <Expenses />
                  </PrivateRoute>
                }
              />

              <Route
                path="/CurrentStock"
                element={
                  <PrivateRoute>
                    <CurrentStock />
                  </PrivateRoute>
                }
              />

              <Route
                path="/DailyProvit"
                element={
                  <PrivateRoute>
                    <DailyProvit />
                  </PrivateRoute>
                }
              />

              <Route
                path="/ProductProvit"
                element={
                  <PrivateRoute>
                    <ProductProvit />
                  </PrivateRoute>
                }
              />

              <Route
                path="/ProductReceipt"
                element={
                  <PrivateRoute>
                    <ProductReceipt />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockIn"
                element={
                  <PrivateRoute>
                    <StockIn />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOut"
                element={
                  <PrivateRoute>
                    <StockOut />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOpname"
                element={
                  <PrivateRoute>
                    <StockOpname />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockCard"
                element={
                  <PrivateRoute>
                    <StockCard />
                  </PrivateRoute>
                }
              />

              <Route
                path="/AddOns"
                element={
                  <PrivateRoute>
                    <AddOns />
                  </PrivateRoute>
                }
              />
            </>
          ) : null}
        </Route>
      </Routes>
    </Router>
  );
}
