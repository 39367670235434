import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import NumberFormat from "../../helpers/functions/numberFormat";
import moment from "moment/moment";
import { FaEye } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import EmptyTableList from "./EmptyTableList";
import { MdLibraryAdd } from "react-icons/md";
import { MdLogin } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaGear } from "react-icons/fa6";
import { TableSortLabel } from "@mui/material";

function TableList({
  tableHeadList,
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  isLoading,
  tableName,
  tableStructure,
  onPreviewDetail,
  onEditData,
  onAddVariant,
  isShowPagination,
  onCreateLogin,
  onChangePassword,
  totalRecord,
  onManagePrice,
  handleRequestSort,
  orderBy,
  order,
}) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1866ee",
      color: theme.palette.common.white,
      fontSize: 13,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#fafafa",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const SortableTableCell = ({ property, label }) => (
    <TableCell>
      <TableSortLabel
        active={orderBy === property}
        direction={orderBy === property ? order : "asc"}
        onClick={() => handleRequestSort(property)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <StyledTableRow key={index}>
        {tableHeadList.map((_, i) => (
          <StyledTableCell key={_.name} component="th" scope="row">
            <Skeleton variant="rectangular" width={75} height={20} />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ));
  };

  const renderStatus = (item, index) => {
    if (item.status === 0) {
      return (
        <Typography
          variant="body2"
          color={"#FF1719"}
          sx={{ fontSize: 11, fontWeight: "bold" }}
        >
          {"Not Ready Sale"}
        </Typography>
      );
    } else if (item.status === 301) {
      return (
        <Typography
          variant="body2"
          color={"#FF1719"}
          sx={{ fontSize: 11, fontWeight: "bold" }}
        >
          {"Not For Sale"}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="body2"
          color={"#007800"}
          sx={{ fontSize: 11, fontWeight: "bold" }}
        >
          {"Ready To Sale"}
        </Typography>
      );
    }
  };

  const renderDataTable = (item, index) => {
    if (tableStructure === "best-seller-products") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell component="th" scope="row">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.productName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{`${item.totalquantity} pcs`}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.netprice)}`}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "dashboard-products") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell component="th" scope="row">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.variantName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{`${item.totalquantity} pcs`}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.netprice)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.netprice)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.netprice)}`}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-balance") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell>
            <Typography variant="body1">{item.branchName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{`Rp. ${NumberFormat(
              0,
              item.netPayment
            )}`}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {" "}
              {`Rp. ${NumberFormat(0, item.netExspenses)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {moment(item.start).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {moment(item.endDate).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-income") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionToName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionNo}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.item)} Items`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.netPayment)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-expenses") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionFromName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {moment(item.createdDate).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionType}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.note}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.netPayment)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "sales") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell variant="body1">
            {item.transactionNo}
          </StyledTableCell>
          <StyledTableCell variant="body1">
            {item.transactionToName}
          </StyledTableCell>
          <StyledTableCell variant="body1">{`${NumberFormat(
            0,
            item.item
          )} Items`}</StyledTableCell>
          <StyledTableCell variant="body1">{`Rp. ${NumberFormat(
            0,
            item.netPayment
          )}`}</StyledTableCell>
          <StyledTableCell variant="body1">
            {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Done" ? "#daffda" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={item.statusName === "Done" ? "#007800" : "white"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "daily-profit") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{`${item.transaction} Pcs`}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.netpayment)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{`Rp. ${item.average}`}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.date}</Typography>
            {/* {moment(item.trxDate).format("DD MMM YYYY, h:mm")} */}
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "current-stock") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.productCode}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.productName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.quantitySum)} pcs`}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "product-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell>
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">{item.masterProductCode}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: 250,
                gap: 2,
                alignItems: "center",
              }}
            >
              <img src={item.imageUrl} className="w-10 h-10 rounded-md" /> */}
            <Typography variant="body1">{item.productName}</Typography>
            {/* </Box> */}
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">{item.categoryName}</Typography>
          </StyledTableCell>
          {/* <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${"#daffda"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"active"}
              </Typography>
            </Box>
          </StyledTableCell> */}
          <StyledTableCell>
            <div className="flex flex-row gap-2">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
              <Tooltip title="Manage Price">
                <IconButton
                  onClick={() => onManagePrice(item, index)}
                  size="medium"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                    color: "#FF5101",
                  }}
                >
                  <FaGear />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Manage Price">
                <IconButton
                  onClick={() => onManagePrice(item, index)}
                  // onClick={() => onAddVariant(item, index)}
                  size="medium"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                    color: "#FF5101",
                  }}
                >
                  <MdLibraryAdd />
                </IconButton>
              </Tooltip> */}
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "variant-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: 250,
                gap: 2,
                alignItems: "center",
              }}
            >
              <img src={item.imageUrl} className="w-10 h-10 rounded-md" /> */}
            <Typography variant="body1">{item.variantName}</Typography>
            {/* </Box> */}
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.productSku}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.defaultTrxPrice)}`}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "addon-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.name}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.addOnsType}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {item.branchCode ? item.branchCode : "-"}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.price)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${item.isActive ? "#daffda" : "#FFAFB0"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={item.isActive ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.isActive ? "Active" : "Non active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Setting Add On">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "product-receive-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionNo}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionType}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.item)} Items`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName == "Done" ? "#daffda" : "#FFAFB0"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={item.statusName == "Done" ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "stockin-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell>
            <Typography variant="body1">{item.transactionFromName}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">
              {`${NumberFormat(0, item.item)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">
              {moment(item.createdDate).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">
              {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex flex-row gap-2">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "stockout-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell>
            <Typography variant="body1">{item.transactionFromName}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">
              {`${NumberFormat(0, item.item)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">
              {moment(item.createdDate).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant="body1">
              {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex flex-row gap-2">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "purchases-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionNo}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.transactionType}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.item)} items`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Done" ? "#daffda" : "#FFAFB0"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={item.statusName == "Done" ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "transfer-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.branchName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.productName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.quantity)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`Rp. ${NumberFormat(0, item.trxPrice)}`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${item.status !== 0 ? "#daffda" : "#FFAFB0"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={item.status !== 0 ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.status !== 0 ? "Done" : "In Progress"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "brand-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.brandName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.createdDate}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.updatedDate}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: "#daffda",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"Active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "category-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.categoryName}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: "#daffda",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"Active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "stock-card-list") {
      const stockOpname =
        parseInt(item.stockopnameplus) + parseInt(item.stockopnameminus);
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.productName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.basestock)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.stockin)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.stockout)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.transferin)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.transferout)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.totalsel)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, stockOpname)} pcs`}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {`${NumberFormat(0, item.finalstock)} pcs`}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "supplier-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.supplierName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.supplierContacName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.supplierPhone}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.supplierEmail}</Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.branchName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.address}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.branchPhone}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Active" ? "#daffda" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={item.statusName === "Active" ? "#007800" : "white"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "user-list") {
      const branchName = item.attachments[0];
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.username}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.userEmail}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">
              {item.attachments.length !== 0
                ? branchName.branchName
                : item.branchCode}
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: "#daffda",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body1"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"Active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Create Login">
                <IconButton
                  onClick={() => onCreateLogin(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <MdLogin />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
              <Tooltip title="change password">
                <IconButton
                  onClick={() => onChangePassword(item, index)}
                  size="medium"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                    color: "#FF5101",
                  }}
                >
                  <RiLockPasswordFill />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "stockOpname-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            <Typography variant="body1">{index + 1}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.branchName}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant="body1">{item.createdDate}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <div className="flex flex-row gap-2">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 35,
                    height: 35,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    }
  };

  return (
    <div>
      {/* <div className="py-3 px-6 flex items-center"> */}
      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHeadList.map((tableHead) => {
                return (
                  <SortableTableCell
                    property={tableHead.id}
                    label={tableHead.name}
                  />
                  // <StyledTableCell align={tableHead.tableAlign}>
                  //   <Typography variant="body1" fontWeight="bold">
                  //     {tableHead.name}
                  //   </Typography>
                  // </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRowsLoader rowsNum={5} />
            ) : data?.length === 0 ? (
              <EmptyTableList
                colSpan={tableHeadList.length}
                tableName={tableName}
              />
            ) : (
              <>{data?.map((item, index) => renderDataTable(item, index))}</>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isShowPagination ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRecord ? totalRecord : data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
      {/* </Paper> */}
    </div>
  );
}

export default TableList;
