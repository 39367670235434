import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadCurrentStock } from "../../utils/Constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import moment from "moment";
import { Box, Container, Paper, Stack } from "@mui/material";

function CurrentStock() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // ==================
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  // ==================
  const [currentStockList, setCurrentStockList] = useState(null);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================
  const [inventoryProductList, setInventoryProductList] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [productSelected, setProductSelected] = useState([]);
  // ==================
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionType] = useState("");

  // ===============
  const onGetCurrentStock = async () => {
    setIsLoading(true);
    const currentPage = page + 1;
    try {
      const res = await api
        .getCurrentStock(
          branchCodeSelected,
          productSelected.productCode,
          rowsPerPage,
          currentPage
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            // setTotalRecord(res.data.totalData);
            setTotalRecord(res.data.totalPage * res.data.limit);
            setCurrentStockList(res.data.data);
            setIsLoading(false);
          } else {
            setCurrentStockList([]);
            onError(res.message);
          }
        })
        .catch((error) => {
          setCurrentStockList([]);
          onError(error.message);
        });
    } catch (error) {
      setCurrentStockList([]);
      onError(error.message);
    }
  };

  const onGetInventoryProduct = async () => {
    try {
      const res = await api
        .getInventoryData(
          branchCodeSelected,
          productSelected.length !== 0 ? productSelected.productCode : ""
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setInventoryProductList(res.data.data);
            setIsLoading(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchList(updatedBranchList);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onHandleReport = async () => {
    setIsSpinner(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .reportDownload(transactionCode, dateFromSelected, dateToSelected)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const fileName = "current-stock-report";
            setIsSpinner(false);
            exportToXLSX(res.data.data, fileName);
            onSuccessSubmit();
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==============
  useEffect(() => {
    const fetch = async () => {
      await onGetCurrentStock();
    };
    fetch();
  }, [page]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    onGetInventoryProduct();
  }, [searchProductName, branchCodeSelected]);

  const onError = async (message) => {
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSuccessSubmit = async () => {
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...currentStockList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setCurrentStockList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  return (
    <Container sx={{ pt: 1, pb: 4 }}>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          py: 3,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Current Stock List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={onHandleReport}
          >
            Download Report
          </Button>
        </Box>

        {/* Search Section */}
        <Grid
          container
          spacing={2}
          sx={{
            px: 3,
            mb: 2,
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE OUTLET
              </Typography>
              <PickerSearch
                dataList={branchList}
                onInputChange={(event, newInputValue) => {
                  setSearchBranchName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setBranchCodeSelected(v.branchCode);
                  } else {
                    setBranchCodeSelected("");
                  }
                }}
                getOptionLabel={(branchList) => branchList.branchName || ""}
                labelPicker="Choose Outlet"
                placeholderPicker="search outlet"
                sizePicker="small"
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                CHOOSE PRODUCT
              </Typography>
              <PickerSearch
                dataList={inventoryProductList}
                onInputChange={(event, newInputValue) => {
                  setSearchProductName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setProductSelected(v);
                  } else {
                    setProductSelected([]);
                  }
                }}
                getOptionLabel={(productList) => productList.productName || ""}
                labelPicker={"Choose Product"}
                placeholderPicker={"search product"}
                sizePicker={"small"}
                isVisibleLabelPicker={false}
              />
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setPage(0);
                onGetCurrentStock();
              }}
              sx={{
                width: "150px",
                mt: { xs: 1, sm: 1, md: 3, lg: 3 },
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* Table List */}
        <TableList
          tableStructure="current-stock"
          tableName={"Current Stock"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadCurrentStock}
          data={currentStockList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          // onPreviewDetail={handlePreviewDetail}
          handleRequestSort={handleRequestSort}
        />
      </Paper>

      {/* <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Current Stock List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={() => {
              onHandleReport();
            }}
          >
            Download Report
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={inventoryProductList}
              onInputChange={(event, newInputValue) => {
                setSearchProductName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setProductSelected(v);
                } else {
                  setProductSelected([]);
                }
              }}
              getOptionLabel={(productList) => productList.productName || ""}
              labelPicker={"Choose Product"}
              placeholderPicker={"search product"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE PRODUCT"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetCurrentStock();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableList
          tableStructure="current-stock"
          tableName={"Current Stock"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadCurrentStock}
          data={currentStockList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={() => alert("detail")}
        />
      </div> */}
    </Container>
  );
}

export default CurrentStock;
