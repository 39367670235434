import React from "react";
import {
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  Box,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function Modal({
  isVisible,
  modalTitle,
  onPressCancel,
  onPressSave,
  buttonSaveTitle,
  children,
  fullScreen,
}) {
  const [scroll, setScroll] = React.useState("paper");
  return (
    <Dialog
      fullScreen={fullScreen ? true : false}
      scroll={scroll}
      fullWidth
      maxWidth="lg"
      open={isVisible}
      onClose={() => console.log("disabled")}
      PaperProps={{
        sx: {
          backgroundColor: "white",
          height: fullScreen ? "100%" : "auto",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "white",
        }}
      >
        {modalTitle}
      </DialogTitle>

      <DialogContent
        dividers={scroll === "paper"}
        sx={{
          flex: 1,
          backgroundColor: "white",
          overflowY: "auto",
        }}
      >
        {children}
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          backgroundColor: "white",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Button onClick={onPressCancel} variant="outlined" sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          onClick={onPressSave}
        >
          {buttonSaveTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;

// function Modal({ visible, onClose, children, modalLabel, widthSize }) {
//   if (!visible) return null;

//   const handleClose = (e) => {
//     if (e.target.id === "modalWrapper") onClose();
//   };

//   return (
//     <div
//       id="modalWrapper"
//       onClick={handleClose}
//       className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
//     >
//       <div
//         className={`${
//           widthSize ? widthSize : "w-[600px]"
//         } flex flex-col overflow-hidden`}
//       >
//         <div className="bg-white max-h-[550px] overflow-y-auto p-2 rounded">
//           <div className="pt-6 px-6 lg:px-8 text-left">
//             <h3 className="mb-4 text-xl font-medium text-gray-900">
//               {modalLabel}
//             </h3>
//           </div>
//           {children}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Modal;
